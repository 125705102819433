<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Jewelry Category
        <div class="card-header-actions">
          <a class="card-header-action" href="jewelry-categories" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row>
          <b-col>
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="categoryPost" novalidate>
              <b-form-group id="emailInputGroup4"
                            label="Category Name"
                            label-for="email">
                <b-form-input id="name"
                              type="text"
                              v-model.trim="$v.form.name.$model"
                              :state="chkState('name')"
                              aria-describedby="nameFeedback"
                              placeholder="Category Name"
                              autocomplete='name' />
                <b-form-invalid-feedback id="nameFeedback" >
                  <span v-if="!$v.form.name.required">- Nama kategori tidak boleh kosong!</span>
                  <span v-if="!$v.form.name.isUnique">- Nama kategori tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInputGroup4"
                            label="Category Slug"
                            label-for="email">
                <b-form-input id="slug"
                              type="text"
                              v-model.trim="$v.form.slug.$model"
                              :state="chkState('slug')"
                              aria-describedby="slugFeedback"
                              placeholder="Category Name"
                              autocomplete='slug' />
                <b-form-invalid-feedback id="nameFeedback" >
                  <span v-if="!$v.form.slug.required">- Slug tidak boleh kosong!</span>
                  <span v-if="!$v.form.slug.isUnique">- Slug tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="lastNameInputGroup2"
                            label="Description"
                            label-for="lastName">
                <b-form-textarea id="description"
                              v-model.trim="$v.form.description.$model"
                              :state="chkState('description')"
                              aria-describedby="descriptionFeedback"
                              placeholder="Description Category">
                              </b-form-textarea>
                <b-form-invalid-feedback id="descriptionFeedback" v-for="(error , index) in errors.form.description" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"


export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        name: '',
        description: '',
        slug: ''
      },
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          name: [],
          slug: [],
          description: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.post(`category-name/${value}`)
              .then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
      description: {
        required,
        minLength: minLength(1)
      },
      slug: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.get(`category-slug/${value}`)
              .then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      }
    }
  },
  methods: {
    categoryPost() {
      this.validate()

      this.isLoading = true
      this.$http.post(`category-store`, {
        name: this.form.name,
        description: this.form.description,
        slug: this.form.slug
      }).then(() => {
        this.isLoading = false
        this.$router.push({name: 'Jewelry Categories'})
        this.$toasted.success('Jewelry category successfully created!')
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
          if(this.errors.status == 422) {
            this.$toasted.error('Please fill out the form that must be required')
            this.errors.message = error.response.data.meta.message;
            this.errors.form.name = this.errors.message.name;
            this.errors.form.slug = this.errors.message.slug;
            this.errors.form.description = this.errors.message.description;
          }
        }
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
